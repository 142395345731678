import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";

import Swal from "sweetalert2";

import resultAPI from "~/utils/api/v2/results";

interface Indicator {
  id: string;
  display_name: string;
}

interface Result {
  file_link: string;
}

interface Props {
  id: string;
}

const Form = ({ id }: Props) => {
  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const [result, setResult] = useState<Result | null>(null);
  const [formData, setFormData] = useState<Record<string, number>>({});
  const [selectedDate, setSelectedDate] = useState<string>("");
  const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const resultResponse = await resultAPI.getResult(id, token);
          setResult(resultResponse.data);

          const indicatorsResponse = await resultAPI.getIndicators(token);
          setIndicators(indicatorsResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [id, token]);

  const handleChange = (indicatorId: string, value: number) => {
    setFormData({ ...formData, [indicatorId]: value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (token) {
      try {
        await resultAPI.createResult(formData, selectedDate, id, token);
        Swal.fire("Creado", "Resultados cargados correctamente", "success");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleDownload = (fileLink: string) => {
    window.open(fileLink, "_blank");
  };

  return (
    <div className="bg-white p-5">
      {result && (
        <button
          onClick={() => handleDownload(result.file_link)}
          className="mb-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 3a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v3h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2V3zm3 5V5a1 1 0 1 1 2 0v3h2a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zm2 6a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2H8z"
              clipRule="evenodd"
            />
          </svg>
          Ver Resultado
        </button>
      )}
      <div className="flex flex-col mb-4">
        <label
          htmlFor="date"
          className="mb-1 text-gray-700"
        >
          Fecha de toma de exámenes:
        </label>
        <input
          type="date"
          id="date"
          name="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"
      >
        {indicators.map((indicator) => (
          <div
            key={indicator.id}
            className="flex flex-col"
          >
            <label className="mb-1">{indicator.display_name}</label>
            <input
              type="number"
              step="0.01"
              onChange={(e) => handleChange(indicator.id, parseFloat(e.target.value))}
              className="p-2 border rounded"
            />
          </div>
        ))}
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded col-span-full"
        >
          Enviar
        </button>
      </form>
    </div>
  );
};

export default Form;
